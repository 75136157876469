import styled from "@emotion/styled";
import { alpha, Backdrop, BackdropProps } from "@mui/material";



export const Base = styled(Backdrop)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) =>
    alpha(theme.palette.background.default, 0.9)};
  z-index: 9999;
  pointer-events: ${({ open }) => (open ? "auto" : "none")};
`;
